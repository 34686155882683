import React from "react";
import get from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default class ReportFormGaderoTuindecoBertonNNBOrders extends React.Component {
    state = {
        fromLatestUpdate: null
    }

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleDateInput = this.handleDateInput.bind(this)
    }

    handleDateInput(event) {
        this.setState({fromLatestUpdate: event.target.value})
    }

    handleSubmit() {
        if(this.state.fromLatestUpdate !== null) {
            get(
                `${process.env.REACT_APP_API_BASE_URL}/reporting/gadero-tuindeco-berton-nnb-orders`,
                {
                    crossDomain: true,
                    responseType: 'blob',
                    params: {
                        'fromLatestUpdate': this.state.fromLatestUpdate
                    }
                }
            )
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'rapport.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })
        } else {
            alert("Vul eerst een datum in")
        }
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="form6.Uuid">
                    <Form.Label>Laatste update vanaf:</Form.Label>
                    <Form.Control type="date" placeholder="Laatste update" onChange={this.handleDateInput}/>
                </Form.Group>
                <Button variant="primary" type="submit">
                    Download Rapport
                </Button>
            </Form>
        );
    }
}