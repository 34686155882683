import React from "react";
import get from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default class ReportFormProductChangesInMonth extends React.Component {
    state = {
        selectedYear: null,
        selectedMonth: null,
    }

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelectYearChange = this.handleSelectYearChange.bind(this);
        this.handleSelectMonthChange = this.handleSelectMonthChange.bind(this);
    }

    handleSelectYearChange(event) {
        this.setState({selectedYear: event.target.value})
    }

    handleSelectMonthChange(event) {
        this.setState({selectedMonth: event.target.value})
    }

    handleSubmit() {
        get(
            `${process.env.REACT_APP_API_BASE_URL}/reporting/product-changes/${this.state.selectedYear}/${this.state.selectedMonth}`,
            {
                crossDomain: true,
                responseType: 'blob',
            }
        )
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'rapport.xlsx');
                document.body.appendChild(link);
                link.click();
            })
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Group>
                    <Form.Label>Kies maand</Form.Label>
                    <Form.Control as="select" onChange={this.handleSelectYearChange}>
                        <option value="-1">Kies een jaar</option>
                        <option value="2021">2021</option>
                        <option value="2020">2020</option>
                        <option value="2019">2019</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Kies maand</Form.Label>
                    <Form.Control as="select" onChange={this.handleSelectMonthChange}>
                        <option value="-1">Kies een maand</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                    </Form.Control>
                </Form.Group>
                <Button variant="primary" type="submit">
                    Download Rapport
                </Button>
            </Form>
        );
    }
}