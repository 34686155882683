import React from 'react';
import Tab from "react-bootstrap/Tab";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import ReportFormAfhaalLijstJochem from "./ReportFormAfhaalLijstJochem";
import ReportFormPicklijstLDSForAfhalersBreukelen from "./ReportFormPicklijstLDSForAfhalersBreukelen";
import ReportFormPicklijstBRForAfhalersBreukelen from "./ReportFormPicklijstBRForAfhalersBreukelen";
import ReportFormTransportLijstMetBarcodesEnNamen from "./ReportFormTransportLijstMetBarcodesEnNamen";
import ReportFormTuindecoBertonNNBOrders from "./ReportFormTuindecoBertonNNBOrders";
import ReportFormGaderoTuindecoBertonNNBOrders from "./ReportFormGaderoTuindecoBertonNNBOrders";
import ReportFormExpectedInvoices from "./ReportFormExpectedInvoices";
import ReportFormVoorraadWijzigingen from "./ReportFormVoorraadWijzigingen";
import ReportFormStock from "./ReportFormStock";
import ReportFormProductsSoldInMonth from "./ReportFormProductsSoldInMonth";
import ReportFormProductChangesInMonth from "./ReportFormProductChangesInMonth";

export default class ReportsList extends React.Component {
    render() {
        return (
            <Tab.Container id="list-group-tabs-example" defaultActiveKey="#rapport1">
                <Row>
                    <Col sm={4}>
                        <ListGroup>
                            <ListGroup.Item action href="#rapport1">
                                Afhaallijst Jochem
                            </ListGroup.Item>
                            <ListGroup.Item action href="#rapport2">
                                Picklijst LDS voor afhalers BR
                            </ListGroup.Item>
                            <ListGroup.Item action href="#rapport3">
                                Picklijst BR voor afhalers BR
                            </ListGroup.Item>
                            <ListGroup.Item action href="#rapport4">
                                Transportlijst + namen + barcodes
                            </ListGroup.Item>
                            <ListGroup.Item action href="#rapport5">
                                Tuindeco+Berton NNB Orders
                            </ListGroup.Item>
                            <ListGroup.Item action href="#rapport6">
                                Gadero+Tuindeco+Berton NNB Orders
                            </ListGroup.Item>
                            <ListGroup.Item action href="#rapport7">
                                Verwachte inkooporders
                            </ListGroup.Item>
                            <ListGroup.Item action href="#rapport8">
                                Voorraad wijzigingen
                            </ListGroup.Item>
                            <ListGroup.Item action href="#rapport9">
                                Voorraad
                            </ListGroup.Item>
                            <ListGroup.Item action href="#rapport10">
                                Verkochte producten per maand
                            </ListGroup.Item>
                            <ListGroup.Item action href="#rapport11">
                                Product wijzigingen per maand
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col sm={8}>
                        <Tab.Content>
                            <Tab.Pane eventKey="#rapport1">
                                <Card>
                                    <Card.Header>Afhaallijst Jochem</Card.Header>
                                    <Card.Body>
                                        <ReportFormAfhaalLijstJochem/>
                                    </Card.Body>
                                </Card>
                            </Tab.Pane>
                            <Tab.Pane eventKey="#rapport2">
                                <Card>
                                    <Card.Header>Picklijst LDS voor afhalers BR</Card.Header>
                                    <Card.Body>
                                        <ReportFormPicklijstLDSForAfhalersBreukelen/>
                                    </Card.Body>
                                </Card>
                            </Tab.Pane>
                            <Tab.Pane eventKey="#rapport3">
                                <Card>
                                    <Card.Header>Picklijst BR voor afhalers BR</Card.Header>
                                    <Card.Body>
                                        <ReportFormPicklijstBRForAfhalersBreukelen/>
                                    </Card.Body>
                                </Card>
                            </Tab.Pane>
                            <Tab.Pane eventKey="#rapport4">
                                <Card>
                                    <Card.Header>Transportlijst + namen + barcodes</Card.Header>
                                    <Card.Body>
                                        <ReportFormTransportLijstMetBarcodesEnNamen/>
                                    </Card.Body>
                                </Card>
                            </Tab.Pane>
                            <Tab.Pane eventKey="#rapport5">
                                <Card>
                                    <Card.Header>Tuindeco+Berton NNB Orders</Card.Header>
                                    <Card.Body>
                                        <ReportFormTuindecoBertonNNBOrders/>
                                    </Card.Body>
                                </Card>
                            </Tab.Pane>
                            <Tab.Pane eventKey="#rapport6">
                                <Card>
                                    <Card.Header>Tuindeco+Berton NNB Orders</Card.Header>
                                    <Card.Body>
                                        <ReportFormGaderoTuindecoBertonNNBOrders/>
                                    </Card.Body>
                                </Card>
                            </Tab.Pane>
                            <Tab.Pane eventKey="#rapport7">
                                <Card>
                                    <Card.Header>Verwachte inkooporders</Card.Header>
                                    <Card.Body>
                                        <ReportFormExpectedInvoices/>
                                    </Card.Body>
                                </Card>
                            </Tab.Pane>
                            <Tab.Pane eventKey="#rapport8">
                                <Card>
                                    <Card.Header>Voorraad wijzigingen</Card.Header>
                                    <Card.Body>
                                        <ReportFormVoorraadWijzigingen/>
                                    </Card.Body>
                                </Card>
                            </Tab.Pane>
                            <Tab.Pane eventKey="#rapport9">
                                <Card>
                                    <Card.Header>Voorraad</Card.Header>
                                    <Card.Body>
                                        <ReportFormStock/>
                                    </Card.Body>
                                </Card>
                            </Tab.Pane>
                            <Tab.Pane eventKey="#rapport10">
                                <Card>
                                    <Card.Header>Verkochte producten per maand</Card.Header>
                                    <Card.Body>
                                        <ReportFormProductsSoldInMonth/>
                                    </Card.Body>
                                </Card>
                            </Tab.Pane>
                            <Tab.Pane eventKey="#rapport11">
                                <Card>
                                    <Card.Header>Product wijzigingen per maand</Card.Header>
                                    <Card.Body>
                                        <ReportFormProductChangesInMonth/>
                                    </Card.Body>
                                </Card>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        );
    }
}