import React from "react";
import get from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default class ReportFormAfhaalLijstJochem extends React.Component {
    state = {
        uuid: null,
    }

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({uuid: event.target.value});
    }

    handleSubmit() {
        get(
            `${process.env.REACT_APP_API_BASE_URL}/reporting/wovar-beitz-afhaallijst/${this.state.uuid}`,
            {
                crossDomain: true,
                responseType: 'blob',
            }
        )
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'rapport.xlsx');
                document.body.appendChild(link);
                link.click();
            })
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="form1.Uuid">
                    <Form.Label>UUID Routelijst</Form.Label>
                    <Form.Control type="text" placeholder="UUID Routelijst" onChange={this.handleChange}/>
                </Form.Group>
                <Button variant="primary" type="submit">
                    Download Rapport
                </Button>
            </Form>
        );
    }
}