import React from "react";
import get from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default class ReportFormVoorraadWijzigingen extends React.Component {
    state = {
        fromDate: null,
        untilDate: null
    }

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleFromDateInput = this.handleFromDateInput.bind(this)
        this.handleUntilDateInput = this.handleUntilDateInput.bind(this)
    }

    handleFromDateInput(event) {
        this.setState({fromDate: event.target.value})
    }

    handleUntilDateInput(event) {
        this.setState({untilDate: event.target.value})
    }

    handleSubmit() {
        if(this.state.fromDate !== null && this.state.untilDate !== null) {
            get(
                `${process.env.REACT_APP_API_BASE_URL}/reporting/voorraad-wijzigingen`,
                {
                    crossDomain: true,
                    responseType: 'blob',
                    params: {
                        'fromDate': this.state.fromDate,
                        'untilDate': this.state.untilDate
                    }
                }
            )
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'rapport.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })
        } else {
            alert("Vul eerst een begin- en einddatum in")
        }
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="form8.Uuid">
                    <Form.Label>Datum vanaf</Form.Label>
                    <Form.Control type="date" placeholder="Datum vanaf" onChange={this.handleFromDateInput}/>
                </Form.Group>
                <Form.Group controlId="form8.Uuid">
                    <Form.Label>Datum tot</Form.Label>
                    <Form.Control type="date" placeholder="Datum tot" onChange={this.handleUntilDateInput}/>
                </Form.Group>
                <Button variant="primary" type="submit">
                    Download Rapport
                </Button>
            </Form>
        );
    }
}