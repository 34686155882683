import React from "react";
import get from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default class ReportFormTuindecoBertonNNBOrders extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {
        get(
            `${process.env.REACT_APP_API_BASE_URL}/reporting/tuindeco-berton-nnb-orders`,
            {
                crossDomain: true,
                responseType: 'blob',
            }
        )
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'rapport.xlsx');
                document.body.appendChild(link);
                link.click();
            })
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                <Button variant="primary" type="submit">
                    Download Rapport
                </Button>
            </Form>
        );
    }
}