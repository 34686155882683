import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import {Provider} from 'react-redux';
import {OidcProvider} from "redux-oidc";

import Callback from './auth/callback';
import PrivateRoute from "./auth/privateRoute";
import userManager from "../utils/userManager";
import store from '../store';
import Homepage from './pages/Home/Home';


function App() {
    const redirectToHome = () => (
        <Redirect to="/"/>
    )

    return (
        <Provider store={store}>
            <OidcProvider store={store} userManager={userManager}>
                    <Router>
                        <Switch>
                            <PrivateRoute role="Reporting" exact path="/" component={Homepage}/>
                            <Route exact path="/signin-oidc" component={Callback}/>
                            <Route component={redirectToHome}/>
                        </Switch>
                    </Router>
            </OidcProvider>
        </Provider>
    );
}

export default App;
