import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import './App.css';
import GaderoNavBar from "../../GaderoNavBar";
import ReportsList from "../../ReportsList";

function Homepage() {
    return (
        <div className="App">
            <GaderoNavBar/>
            <Container>
                <Row>
                    <Col>
                        <header className="App-header">
                            <ReportsList/>
                        </header>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Homepage;
