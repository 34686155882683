import React from "react";
import get from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

export default class ReportFormPicklijstLDSForAfhalersBreukelen extends React.Component {
    state = {
        orderStatuses: [],
        uuid: null,
        selectedOrderStatus: null,
    }

    constructor(props) {
        super(props);
        this.handleUuidChange = this.handleUuidChange.bind(this);
        this.handleOrderStatusChange = this.handleOrderStatusChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        get(`${process.env.REACT_APP_API_BASE_URL}/orderstatus/breukelen-afhalen`, { crossDomain: true })
            .then(res => {
                this.setState({ orderStatuses: res.data });
                this.setState({ selectedOrderStatus: this.state.orderStatuses[0].id });
            });
    }

    renderOrderStatusSelectOptions() {
        return this.state.orderStatuses.map((orderStatus) =>
            <option value={orderStatus.id} key={orderStatus.id}>{orderStatus.status}</option>
        );
    }

    handleUuidChange(event) {
        this.setState({uuid: event.target.value});
    }

    handleOrderStatusChange(event) {
        this.setState({selectedOrderStatus: parseInt(event.target.value)});
    }

    handleSubmit() {
        get(
            `${process.env.REACT_APP_API_BASE_URL}/reporting/picklijst-lds-afhalers-breukelen/${this.state.selectedOrderStatus}/${this.state.uuid}`,
            {
                crossDomain: true,
                responseType: 'blob',
            }
        )
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'rapport.xlsx');
                document.body.appendChild(link);
                link.click();
            })
    }

    render() {
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="form2.Uuid">
                    <Form.Label>UUID Routelijst</Form.Label>
                    <Form.Control type="text" placeholder="UUID Routelijst" onChange={this.handleUuidChange}/>
                </Form.Group>
                <Form.Group controlId="form2.OrderStatusSelect">
                    <Form.Label>Kies een Orderstatus</Form.Label>
                    <Form.Control as="select" onChange={this.handleOrderStatusChange}>
                        {this.renderOrderStatusSelectOptions()}
                    </Form.Control>
                </Form.Group>
                <Button variant="primary" type="submit">
                    Download Rapport
                </Button>
            </Form>
        );
    }
}