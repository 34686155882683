import React from 'react';
import Navbar from "react-bootstrap/Navbar";
import logo from '../logo_gadero_wit.png';

function GaderoNavBar() {
    return (
        <Navbar variant="dark" style={{ backgroundColor: "#B60827"}}>
            <Navbar.Brand href="#home">
                <img
                    alt=""
                    src={logo}
                    height="30"
                    className="d-inline-block align-top"
                />{' '}
            </Navbar.Brand>
        </Navbar>
    );
}

export default GaderoNavBar;